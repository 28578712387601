<template>
    <section class="other">

    </section>
</template>

<script>
    export default {
        name: "other",
        props: {},
        data() {
            return {};
        },
        components: {},
        async created() {},
        methods: {}
    }
</script>

<style scoped>

</style>